import { Container, Row, Col, Hidden } from 'react-grid-system';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faBuilding, faClock, faCopyright, faMapMarkerAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useRef } from 'react';

import ngLogo from './logo.png'
import scotsLogo from './the-scots-logo.png'
import clipboardLogo from './clipboard-logo.png'
import meJPG from './me.jpg'
import meWEBP from './me.webp'

const Spacer = ({ y }) => <div className="spacer" style={{ height: y+'em' }}></div>

const Job = ({ company, role, period, children }) => {
  return <div className="job">
    <h3>{company.name}</h3>
    <img className="job-image" alt={`${company.name} logo`} src={company.logo} />
    <span className="job-subtitle"><i>{role}</i><hr className="vr" /><i>{period}</i></span>
    <p>{children}</p>
  </div>
}

function App() {
  const { scrollYProgress } = useViewportScroll()
  const contentRef = useRef()
  const scrollTo = () => {
    contentRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  const arrowAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 0, 0])
  return (
    <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 1 }}>
      <Container>
        <Row align="center" style={{ alignContent: 'center' }} className="title-row">
          <Col sm={12} style={{ textAlign: 'center' }}>
            <picture>
              <source srcset={meWEBP} type="image/webp" />
              <source srcset={meJPG} type="image/jpeg" /> 
              <img className="avatar" alt="Tom Lister Photo" />
            </picture>
            <div className="main-title">tom lister.</div>
            <div className="sub-title">software developer.</div>
          </Col>
          <Col sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <ul className="socials">
              <li>
                <a href="mailto:tom@tomlister.net"><FontAwesomeIcon fixedWidth icon={faPaperPlane} />Email</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/tomlister3/"><FontAwesomeIcon fixedWidth icon={faLinkedinIn} />LinkedIn</a>
              </li>
              <li>
                <a href="https://github.com/tomlister"><FontAwesomeIcon fixedWidth icon={faGithub} />GitHub</a>
              </li>
              <li>
                <a href="https://twitter.com/tomlist3r/"><FontAwesomeIcon fixedWidth icon={faTwitter} />Twitter</a>
              </li>
            </ul>
          </Col>
          <motion.div style={{ opacity: arrowAnim }} className="arrow"><FontAwesomeIcon fixedWidth icon={faArrowDown} onClick={scrollTo} /></motion.div>
        </Row>
        <Row>
          <Col sm={12}>
            <span ref={contentRef}></span>
            <Spacer y={1} />
            <h1>Hi, I'm Tom. 👋</h1>
            <hr></hr>
          </Col>
          <Col sm={6}>
            <p>
              I'm a software developer based in Sydney, Australia. <br />
              Programming is my calling, and I enjoy working on projects big and small. <br /> 
              In my spare time, I dabble with music and electronics.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h2>Employment</h2>
            <hr></hr>
          </Col>
          <Col sm={6}>
            <Job 
              company={{ name: 'OSINT Combine', logo: "https://static.wixstatic.com/media/c17c29_62adc37144ad477294ad95af5fa4048f~mv2.png/v1/fill/w_1474,h_588,al_c/c17c29_62adc37144ad477294ad95af5fa4048f~mv2.png" }}
              role="Junior Fullstack Developer"
              period="Jan 2022 -"
            >
              At OSINT Combine, I work with the dev team to develop the NexusXplore OSINT Investigation platform. My duties involve implementing frontend and backend features.
            </Job>
            </Col>
          <Col sm={6}>
            <Job 
              company={{ name: 'Clipboard', logo: clipboardLogo }}
              role="Web Development Intern"
              period="Jan 2020 - Jan 2020"
            >
              At Clipboard, I worked with the Angular framework and node.js to implement features in their product. I worked in an Agile environment and was involved in daily scrum meetings.
            </Job>
            </Col>
          <Col sm={6}>
            <Job 
              company={{ name: 'The Scots College', logo: scotsLogo }} 
              role="Casual IT Help Desk Technician"
              period="Jan 2016 – Jan 2019"
            >
              At The Scots College ICT Help Desk I conducted laptop inductions, solved issues with student and staff machines (macOS and Windows), and familiarised myself with the JAMF device management system along with the SolarWinds Ticketing System. I learnt to solve problems and create solutions for a wide variety of computing issues. In 2018, I developed bespoke induction management software to facilitate a smooth laptop induction program.
            </Job>
            </Col>
            <Col sm={6}>
            <Job 
              company={{ name: 'New Ground Digital', logo: ngLogo }} 
              role="Web Development Intern"
              period="Dec 2017 - Dec 2017"
            >
              At New Ground Digital, I experienced working in a software design company. I created React components and wrote back end code for a Curriculum Management System. I learnt how linting allows for great code readability and prevents mistakes before pushing to a git repo.
            </Job>
          </Col>
          </Row>
          <Row>
          <Col sm={6}>
            <h2>Education</h2>
            <hr></hr>
            <h3>The Scots College</h3>
            <span className="generic-subtitle"><i>HSC Student</i><hr className="vr" /><i>Jan 2009 - September 2021</i></span>
            <p>1st GPS Rifle Shooting Team, Music Technology.</p>
            <h3>University of Technology Sydney</h3>
            <span className="generic-subtitle"><i>Software Engineering</i><hr className="vr" /><i>Jan 2022 -</i></span>
            <p>President of UTS Target Rifle Club.</p>
          </Col>
          <Col sm={6}>
            <h2>Open Source Work</h2>
            <hr></hr>
            <h3>Ebiten</h3>
            <span className="generic-subtitle"><i>Contributor</i><hr className="vr" /><i>Jan 2020 - </i></span>
            <p>Ebiten is an open source game library for the Go programming language. Ebiten's simple API allows you to quickly and easily develop 2D games that can be deployed across multiple platforms.</p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <h2>Skills</h2>
            <hr></hr>
            <ul>
              <li>Fullstack Software Development</li>
              <li>Backtesting</li>
              <li>Web Design</li>
              <li>Go Programming Language</li>
            </ul>
          </Col>
          <Col sm={6}>
            <h2>Accolades</h2>
            <hr></hr>
            <h3>Duke of Edinburgh's Award</h3>
            <span className="generic-subtitle"><i>Bronze</i><hr className="vr" /><i>Jan 2019</i></span>
          </Col>
          <Col sm={12}>
            <Spacer y={4} />
          </Col>
        </Row>
        <Row className="footer">
          <Col sm={12}>
            <h2>Let's build something.</h2>
            <hr />
            <div className="footer-logo">tom lister.</div>
            <Spacer y={1} />
            <ul className="footer-list">
            <li>
              <a href="mailto:tom@tomlister.net"><FontAwesomeIcon fixedWidth icon={faPaperPlane} /> tom@tomlister.net</a>
            </li>
            <li><FontAwesomeIcon fixedWidth icon={faMapMarkerAlt} /> Sydney, Australia</li>
            <li><FontAwesomeIcon fixedWidth icon={faClock} /> GMT+10/11</li>
            <li><FontAwesomeIcon fixedWidth icon={faCopyright} /> 2023 Tom Lister</li>
            <li><FontAwesomeIcon fixedWidth icon={faBuilding} /> ABN 29740608120</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}

export default App;
